import { FixedSizeList as List } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import React, { memo } from "react"
import { Anime } from "../types/AnimeTypes"
import { FixedSizeGrid as Grid, areEqual } from "react-window"
import data from "../../Data.json"
import "../pages/index.css"
import AnimeCard from "./AnimeCard"
import { InputBase, TextField } from "@mui/material"
var levenshtein = require("fast-levenshtein")

const Cell = memo(({ columnIndex, rowIndex, style, data }: any) => {
  const { animes, columnCount } = data

  const singleColumnIndex = columnIndex + rowIndex * columnCount
  let anime = animes[singleColumnIndex]
  return (
    <div style={style}>
      {anime && (
        <div
          key={anime.Title}
          style={{
            width: "286px",
            height: "395px",
            display: "inline-block",
          }}
        >
          <AnimeCard anime={anime} />
        </div>
      )}
    </div>
  )
}, areEqual)

interface AnimeDisplayState {
  search?: string
}
interface AnimeDisplayProps {
  search?: string
}

export default class AnimeDisplay extends React.Component<
  AnimeDisplayProps,
  AnimeDisplayState
> {
  constructor(props) {
    super(props)

    this.state = { search: this.props.search ?? undefined }
    this.HandleSearch = this.HandleSearch.bind(this)
  }
  // TODO: Clean up sometime
  HandleSearch(
    animes: Anime[],
    searchQuery: string,
    columCount: number
  ): Anime[] {
    // Find animes that include the search query, case-insensitive
    let filteredanimes = animes.filter(anime =>
      anime.Title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    console.log(
      `Found ${filteredanimes.length} animes after search. Searched with ${this.state.search}.`
    )
    // find a column of animes closest to the search query
    filteredanimes = filteredanimes.concat(
      animes
        .filter(
          anime =>
            filteredanimes.some(
              filteredAnime => filteredAnime.Title == anime.Title
            ) == false
        )
        .sort((a, b) =>
          levenshtein.get(a.Title, searchQuery) >
          levenshtein.get(b.Title, searchQuery)
            ? 1
            : -1
        )
        .slice(0, columCount)
    )
    // if we find less then 12 animes, we will add a few more in to try to supplement the search results...
    // We're trying to find similiar anime that aren't already in the search results and match some of the categories as existing found anime....
    var otherAnime = animes.filter(
      otheranime =>
        filteredanimes.some(
          searchAnime => searchAnime.Title == otheranime.Title
        ) == false &&
        otheranime.GenericData.Categories != undefined &&
        otheranime.GenericData.Categories.every(cat =>
          filteredanimes.some(searchAnime =>
            searchAnime.GenericData.Categories.includes(cat)
          )
        )
    )
    // Weight is based on search popularity....
    let suggestions = otherAnime
      .sort((a, b) => (a.GenericData.Weight < b.GenericData.Weight ? 1 : -1))
      .slice(0, columCount * 3 - filteredanimes.length) // We want to fill at least 3 columns of results
    filteredanimes = filteredanimes.concat(suggestions)

    return filteredanimes
  }
  render() {
    let animes: Anime[] = data

    return (
      <React.Fragment>
        <div
          style={{
            minHeight: "100vh",
            marginTop: "2em",
            position: "relative",
            margin: "auto",
            width: "99%",
            paddingTop: "5em",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search"
            variant="filled"
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            onChange={event => {
              window.history.replaceState(
                {},
                "",
                `/search/${event.target.value}`
              )
              this.setState({ search: event.target.value })
            }}
            value={this.state.search}
            className="animeSearch"
          />
          <AutoSizer defaultWidth={1920} defaultHeight={1080}>
            {({ width, height }) => {
              const animeWidth = 300 // how wide each anime card is
              const animeHeight = 450 // how high each anime card is
              const columnCount = Math.floor(width / animeWidth) // how many columns we can have
              if (this.state.search != undefined) {
                animes = this.HandleSearch(
                  animes,
                  this.state.search,
                  columnCount
                ) // handle search if input
              }
              const rowCount = Math.ceil(animes.length / columnCount)
              const itemData = { animes, columnCount }
              return (
                <Grid
                  className="grid"
                  width={width}
                  height={height}
                  columnCount={columnCount}
                  columnWidth={animeWidth}
                  rowCount={rowCount}
                  rowHeight={animeHeight}
                  itemData={itemData}
                >
                  {Cell}
                </Grid>
              )
            }}
          </AutoSizer>
        </div>
      </React.Fragment>
    )
  }
}
