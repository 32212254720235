import { Card, CardContent, CardMedia, createTheme, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { Anime } from "../types/AnimeTypes";

import "../pages/index.css"
import slugify from "slugify";
import { Link } from "gatsby";


interface AnimeCardProps {
    anime: Anime
}




export default function AnimeCard(props: AnimeCardProps) {
    return (
      <Link
      to={
        "/" +
        (props.anime.GenericData.Slug == undefined ||
          props.anime.GenericData.Slug == ""
          ? slugify(props.anime.Title, { strict: true })
          : props.anime.GenericData.Slug)
      }
    >
                <Card sx={{ width: '100%', height: '100%' }} className="suggestionsFlexItem flexitem cardMargins">
                  <CardMedia
                    component="img"
                    image={props.anime.CoverImage} 
                    alt={`${props.anime.Title} Cover Image`}
                    className="cardImage"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="cardTitle"
                    >
                      {props.anime.Title}
                    </Typography>
                  </CardContent>
                </Card>
                </Link>
    )
}